import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  BadgeTransactionDisplayedApi,
  GetSupportingDocumentType,
  PublicAuthorizationType,
  PublicBadgeTransactionDisplayed,
  PublicTransactionsPropsType,
  PublicTransactionStatus,
  PublicTransactionType,
  SendSmsPayload,
  SupportingDocumentStatus,
} from "./transactionsAPI";
import axios from "axios";

export interface TransactionsState {
  transactionsListStatus: QueryStatus;
  transactionsList: PublicTransactionsPropsType[] | null;
  authorizationsListStatus: QueryStatus;
  authorizationsList: PublicTransactionsPropsType[] | null;
  supportingDocumentUrl: string;
  isSupportingDocumentPdf: boolean;
  sendSmsStatus: QueryStatus;
  sendSmsReplaceInvalidStatus: QueryStatus;
  hasBackendError: boolean;
  lastReminderDateStatus: QueryStatus;
  lastReminderDate: Date | null;
  asfTransactionsListStatus: QueryStatus;
  asfTransactionsList: PublicBadgeTransactionDisplayed[] | null;
}

const initialState: TransactionsState = {
  transactionsListStatus: "idle",
  transactionsList: null,
  authorizationsListStatus: "idle",
  authorizationsList: null,
  supportingDocumentUrl: "",
  isSupportingDocumentPdf: true,
  sendSmsStatus: "idle",
  sendSmsReplaceInvalidStatus: "idle",
  hasBackendError: false,
  lastReminderDateStatus: "idle",
  lastReminderDate: null,
  asfTransactionsListStatus: "idle",
  asfTransactionsList: null,
};

export const transactionsListAsync = createAsyncThunk(
  "transactionsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();

    const response = await axios.get<Array<PublicTransactionType>>(
      `transactions/company/${companyUuid}`,
    );
    return response.data.map(
      ({
        amount,
        executionDate,
        supportingDocumentUuid,
        status,
        rule,
        card,
        areSupportingDocumentsMandatory,
        ...payload
      }) => {
        const isStatusPending =
          status === "PENDING_ADJUSTEMENT" ||
          status === "PENDING_CAPTURE" ||
          status === "PENDING_CONFIRMATION";

        return {
          ...payload,
          areSupportingDocumentsMandatory,
          amount:
            status === "CANCELED" ? -(amount.value / 100) : amount.value / 100,
          status: isStatusPending
            ? "PENDING"
            : (status as PublicTransactionStatus),
          supportingDocumentStatus:
            areSupportingDocumentsMandatory &&
            supportingDocumentUuid &&
            status !== "CANCELED"
              ? "ACCEPTED"
              : areSupportingDocumentsMandatory && status !== "CANCELED"
                ? ("MISSING" as SupportingDocumentStatus)
                : "-",
          date: executionDate,
          supportingDocumentUuid,
          ruleUuid: rule,
          cardUuid: card,
        };
      },
    );
  },
);

export const asfTransactionsListAsync = createAsyncThunk(
  "asfTransactionsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();

    const response = await axios.get<BadgeTransactionDisplayedApi[]>(
      `asf_transactions/company/${companyUuid}`,
    );
    return response.data.map((r) => {
      return {
        ...r,
        date: r.executionDate,
        amount: r.type === "REFUND" ? -(r.amount / 100) : r.amount / 100,
      };
    });
  },
);

export const authorizationsListAsync = createAsyncThunk(
  "authorizationsRefusedList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<PublicAuthorizationType>>(
      `authorizations_rejected/company/${companyUuid}`,
    );
    return response.data.map(({ executionDate, ...payload }) => {
      return {
        ...payload,
        amount: 0,
        status: "REJECTED" as PublicTransactionStatus,
        date: executionDate,
        supportingDocumentStatus: "-" as SupportingDocumentStatus,
      };
    });
  },
);

export const getSupportingDocument = createAsyncThunk(
  "getSupportingDocument/call",
  async (uuid: string, thunkAPI) => {
    const axios = authAxios();
    const response = await axios.post<GetSupportingDocumentType>(
      `supporting_documents/${uuid}/get_presigned_url`,
    );
    void thunkAPI.dispatch(createUrlSupportingDocumentAsync(response.data));
  },
);

export const sendSmsAsync = createAsyncThunk(
  "sendSms/call",
  async (payload: SendSmsPayload, thunkAPI) => {
    const axios = authAxios();
    await axios.post(`send_sms_reminder_supporting_document`, payload);
    void thunkAPI.dispatch(
      getLastReminderDateAsync({ transaction: payload.transactionUuid }),
    );
  },
);

export const sendSmsReplaceInvalidAsync = createAsyncThunk(
  "sendSmsReplaceInvalid/call",
  async (payload: SendSmsPayload) => {
    const axios = authAxios();
    await axios.post(`send_sms_replace_invalid_supporting_document`, payload);
  },
);

export const getLastReminderDateAsync = createAsyncThunk(
  "GetLastReminderDate/call",
  async (payload: { transaction: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ date: string }>(
      `transaction/${payload.transaction}/get_last_reminder_date`,
    );
    return { date: response.data.date ? new Date(response.data.date) : null };
  },
);

export const createUrlSupportingDocumentAsync = createAsyncThunk(
  "createUrlSupportingDocument/call",
  async (supportingDocument: GetSupportingDocumentType, thunkAPI) => {
    if (supportingDocument.presignedUrl && supportingDocument.fileName) {
      const fileNameSplit = supportingDocument.fileName.split(".");
      const extension = fileNameSplit[fileNameSplit.length - 1];
      const response = await axios({
        url: supportingDocument.presignedUrl,
        method: "GET",
        responseType: "arraybuffer",
      });
      let url = "";
      if (extension === "pdf") {
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" }),
        );
        thunkAPI.dispatch(
          transactionsSlice.actions.setIsSupportingDocumentPdf(),
        );
      } else {
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: `image/${extension}` }),
        );
      }
      thunkAPI.dispatch(
        transactionsSlice.actions.setSupportingDocumentUrl({ url }),
      );
    }
  },
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetTransactionsListStatus(state) {
      state.transactionsListStatus = "idle";
    },
    resetAuthorizationsListStatus(state) {
      state.authorizationsListStatus = "idle";
    },
    resetSupportingDocumentUrl(state) {
      state.supportingDocumentUrl = "";
      state.isSupportingDocumentPdf = false;
    },
    setIsSupportingDocumentPdf(state) {
      state.isSupportingDocumentPdf = true;
    },
    setSupportingDocumentUrl(state, action: PayloadAction<{ url: string }>) {
      state.supportingDocumentUrl = action.payload.url;
    },
    setHasBackendError(state) {
      state.hasBackendError = true;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(transactionsListAsync.pending, (state) => {
        state.transactionsListStatus = "processing";
        state.supportingDocumentUrl = "";
        state.isSupportingDocumentPdf = false;
      })
      .addCase(transactionsListAsync.fulfilled, (state, action) => {
        state.transactionsList = action.payload;
        state.transactionsListStatus = "success";
        state.sendSmsReplaceInvalidStatus = "idle";
      })
      .addCase(transactionsListAsync.rejected, (state) => {
        state.transactionsListStatus = "failed";
        state.transactionsList = [];
      })
      .addCase(authorizationsListAsync.pending, (state) => {
        state.authorizationsListStatus = "processing";
      })
      .addCase(authorizationsListAsync.fulfilled, (state, action) => {
        state.authorizationsList = action.payload;
        state.authorizationsListStatus = "success";
      })
      .addCase(authorizationsListAsync.rejected, (state) => {
        state.authorizationsListStatus = "failed";
        state.authorizationsList = [];
      })
      .addCase(sendSmsAsync.pending, (state) => {
        state.sendSmsStatus = "processing";
      })
      .addCase(sendSmsAsync.fulfilled, (state) => {
        state.sendSmsStatus = "success";
      })
      .addCase(sendSmsAsync.rejected, (state) => {
        state.sendSmsStatus = "failed";
      })
      .addCase(sendSmsReplaceInvalidAsync.pending, (state) => {
        state.sendSmsReplaceInvalidStatus = "processing";
      })
      .addCase(sendSmsReplaceInvalidAsync.fulfilled, (state) => {
        state.sendSmsReplaceInvalidStatus = "success";
      })
      .addCase(sendSmsReplaceInvalidAsync.rejected, (state) => {
        state.sendSmsReplaceInvalidStatus = "failed";
      })
      .addCase(getLastReminderDateAsync.pending, (state) => {
        state.lastReminderDateStatus = "processing";
      })
      .addCase(getLastReminderDateAsync.fulfilled, (state, action) => {
        state.lastReminderDate = action.payload.date;
        state.lastReminderDateStatus = "success";
      })
      .addCase(getLastReminderDateAsync.rejected, (state) => {
        state.lastReminderDateStatus = "failed";
      })
      .addCase(asfTransactionsListAsync.pending, (state) => {
        state.asfTransactionsListStatus = "processing";
      })
      .addCase(asfTransactionsListAsync.fulfilled, (state, action) => {
        state.asfTransactionsList = action.payload;
        state.asfTransactionsListStatus = "success";
      })
      .addCase(asfTransactionsListAsync.rejected, (state) => {
        state.asfTransactionsListStatus = "failed";
      });
  },
});

export const selectTransactionsList = (state: RootState) =>
  state.transactions.transactionsList;
export const selectTransactionsListStatus = (state: RootState) =>
  state.transactions.transactionsListStatus;

export const selectAsfTransactionsList = (state: RootState) =>
  state.transactions.asfTransactionsList;
export const selectAsfTransactionsListStatus = (state: RootState) =>
  state.transactions.asfTransactionsListStatus;

export const selectAuthorizationsList = (state: RootState) =>
  state.transactions.authorizationsList;
export const selectAuthorizationsListStatus = (state: RootState) =>
  state.transactions.authorizationsListStatus;

export const selectSupportingDocumentUrl = (state: RootState) =>
  state.transactions.supportingDocumentUrl;

export const selectIsSupportingDocumentPdf = (state: RootState) =>
  state.transactions.isSupportingDocumentPdf;

export const selectSendSmsStatus = (state: RootState) =>
  state.transactions.sendSmsStatus;

export const selectSendSmsReplaceInvalidStatus = (state: RootState) =>
  state.transactions.sendSmsReplaceInvalidStatus;

export const selectHasBackendError = (state: RootState) =>
  state.transactions.hasBackendError;
export const selectLastReminderDate = (state: RootState) =>
  state.transactions.lastReminderDate;
export const selectLastReminderDateStatus = (state: RootState) =>
  state.transactions.lastReminderDateStatus;

export default transactionsSlice.reducer;
