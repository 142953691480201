import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  billingsSlice,
  selectDeleteRecipientEmailStatus,
  selectUpdateCompanyStatus,
} from "./billingsSlice";
import { useEffect, useState } from "react";
import {
  Spacer,
  PageDS,
  triggerToast,
  ToastContainer,
  TabMenu,
  LaunchPage,
} from "@qivia/ui";
import { loadCompanyAsync, selectCompany, selectManager } from "./../homeSlice";
import { EmailsFrame, StatementTab } from "./../billings/statementsTab";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceTab } from "./invoicesTab";
import { AccountTab } from "./accountTab";
import { BankStatementTab } from "./bankStatementsTab";
import {
  sepaDebitSlice,
  selectCreateExternalBankAccountStatus,
} from "./sepaDebitSlice";
import { SepaDebitModals } from "./modals/sepaDebitModals";

export const Billings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "account",
  );
  const company = useAppSelector(selectCompany);
  const manager = useAppSelector(selectManager);
  const updateCompanyStatus = useAppSelector(selectUpdateCompanyStatus);
  const deleteRecipientEmailStatus = useAppSelector(
    selectDeleteRecipientEmailStatus,
  );
  const createExternalBankAccountStatus = useAppSelector(
    selectCreateExternalBankAccountStatus,
  );

  const tabs = [
    {
      name: "account",
      title: t("billings.tab.account"),
    },
    {
      name: "invoices",
      title: t("billings.tab.invoices"),
    },
    {
      name: "statements",
      title: t("billings.tab.statements"),
    },
    {
      name: "bankStatements",
      title: t("billings.tab.bankStatements"),
    },
  ];

  useEffect(() => {
    if (selectedTab && params.sepa_debit === undefined) {
      navigate(`/home/billings/${selectedTab}`);
    } else if (selectedTab) {
      navigate(
        `/home/billings/${selectedTab}${
          params.sepa_debit !== undefined ? `/${params.sepa_debit}` : "/"
        }`,
      );
    }
  }, [navigate, params.sepa_debit, selectedTab]);

  useEffect(() => {
    if (updateCompanyStatus === "success") {
      triggerToast(t("billings.emailsFrame.save.success") || "", "valid");
      if (company) {
        void dispatch(loadCompanyAsync({ uuid: company.uuid }));
        dispatch(billingsSlice.actions.resetUpdateCompanyStatus());
      }
    } else if (updateCompanyStatus === "failed") {
      triggerToast(t("billings.emailsFrame.save.error") || "", "error");
    }
  }, [company, dispatch, t, updateCompanyStatus]);

  useEffect(() => {
    if (deleteRecipientEmailStatus === "success") {
      triggerToast(t("billings.emailsFrame.delete.success") || "", "valid");
      if (company) {
        void dispatch(loadCompanyAsync({ uuid: company.uuid }));
        dispatch(billingsSlice.actions.resetDeleteRecipientEmailStatus());
      }
    } else if (deleteRecipientEmailStatus === "failed") {
      triggerToast(t("billings.emailsFrame.delete.error") || "", "error");
    }
  }, [company, deleteRecipientEmailStatus, dispatch, t]);

  useEffect(() => {
    if (createExternalBankAccountStatus === "success") {
      if (company) {
        void dispatch(loadCompanyAsync({ uuid: company.uuid }));
      }
      dispatch(sepaDebitSlice.actions.resetExternalBankAccountStatus());
      triggerToast(
        t("billings.sepaDebit.createExternalBankAccount.success") || "",
        "valid",
      );
    } else if (createExternalBankAccountStatus === "failed") {
      triggerToast(
        t("billings.sepaDebit.createExternalBankAccount.error") || "",
        "error",
      );
    }
  }, [company, createExternalBankAccountStatus, dispatch, t]);

  if (!company || !manager) {
    return;
  }

  if (createExternalBankAccountStatus === "processing") {
    return <LaunchPage />;
  }

  return (
    <>
      <PageDS
        title={params.sepa_debit === undefined ? t("billings.title") : ""}
        toaster={<ToastContainer />}
        topElement={
          params.sepa_debit === undefined ? (
            <>
              <Spacer y={1} />
              <TabMenu
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              <Spacer y={0.5} />
              {selectedTab === "statements" && ( //pour l'instant, car plus tard on laffiche dans les 2 cas
                <>
                  <EmailsFrame company={company} />
                  <Spacer y={2} />
                </>
              )}
            </>
          ) : (
            <></>
          )
        }
      >
        <>
          {params.sepa_debit === undefined ? (
            <>
              <StatementTab />
              <InvoiceTab />
              <AccountTab />
              <BankStatementTab />
            </>
          ) : (
            <SepaDebitModals
              company={company}
              manager={manager}
              params={params}
            />
          )}
        </>
      </PageDS>
    </>
  );
};
