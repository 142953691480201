import { CSSProperties, ReactNode, useState } from "react";
import styled from "styled-components";
import { TableHeaderDS, TableHeaderProps } from "../table/TableHeader";
import { Spacer } from "../Spacer";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { MenuOptions } from "../Menu/MenuOptions";
import { MoreHorizOutlined } from "../../materialUi/materialUi";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { TextCapitalized } from "../text/TextCapitalized";
import { Trans, useTranslation } from "react-i18next";
import { EmptyTable } from "../../../assets/assets";
import { FixedSizeList } from "react-window";
import { AutoSizer } from "react-virtualized";
import { SearchBarDS, SearchBarDSProps } from "../SearchBar";
import { t } from "i18next";
import { EmptyTableComponent } from "../table/EmptyTableComponent";

interface VirtualTableProps<
  Key extends string,
  T extends Record<Key, string | number | null>,
> {
  data: Array<T>;
  headers: Record<keyof T, TableHeaderProps>;
  headerOverlayed?: JSX.Element | string;
  render?: (row: T) => (key: Key) => ReactNode;
  onClickRow?: (props: T) => void;
  setRowHover?: (props: T | null) => void;
  menuOptions?: OptionWithProps<Key, T>[];
  height?: number;
  emptyContent?: JSX.Element;
  hasBackendError?: boolean;
  hasSmallFirstRow?: boolean;
  searchBar?: SearchBarDSProps<T>;
}

interface OptionWithProps<
  Key extends string,
  T extends Record<Key, string | number | null>,
> {
  label: string | JSX.Element;
  action: (props: T) => void;
  color?: string;
  icon?: JSX.Element;
}

type VirtualRowParams<
  Key extends string,
  T extends Record<Key, string | number | null>,
> = {
  data: T[];
  keys: Key[];
  render?: (row: T) => (key: Key) => ReactNode;
  onClickRow?: (props: T) => void;
  setRowHover?: (props: T | null) => void;
  menuOptions?: OptionWithProps<Key, T>[];
  hasSmallFirstRow?: boolean;
};

const VirtualRow =
  <Key extends string, T extends Record<Key, string | number | null>>(
    params: VirtualRowParams<Key, T>,
  ) =>
  (props: { style: CSSProperties; index: number }) => (
    <StyledRow
      $hasAction={!!params.onClickRow}
      onClick={() => {
        params.onClickRow?.(params.data[props.index]);
      }}
      style={props.style}
    >
      {params.keys.map((key, i) => (
        <StyledCell
          $first={i === 0}
          key={key}
          $hasSmallWidth={!!(params.hasSmallFirstRow && i === 0)}
        >
          <Spacer x={1} />
          <StyledColumn>
            <Spacer y={1} />
            <StyledText $hasAction={false}>
              {params.render ? (
                params.render(params.data[props.index])(key)
              ) : (
                <> {`${params.data[props.index][key] || "-"}`} </>
              )}{" "}
            </StyledText>
            <Spacer y={1} />
          </StyledColumn>
          <Spacer x={1} />
        </StyledCell>
      ))}
      {params.menuOptions && (
        <MenuOptionsCell
          menuOptions={params.menuOptions}
          data={params.data[props.index]}
        />
      )}
    </StyledRow>
  );

const MenuOptionsCell = <
  Key extends string,
  T extends Record<Key, string | number | null>,
>(props: {
  menuOptions: OptionWithProps<Key, T>[];
  data: T;
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const outsideClickMenuOptions = useOutsideClick(() => {
    setIsMenuVisible(false);
  });
  return (
    <StyledMenuOptionsCell onClick={(event) => event.stopPropagation()}>
      {isMenuVisible && (
        <StyledMenuOptionsDisplay ref={outsideClickMenuOptions}>
          <StyledAbsolute>
            <MenuOptions
              options={props.menuOptions.map((option) => {
                return {
                  ...option,
                  action: () => {
                    option.action(props.data);
                    setIsMenuVisible(false);
                  },
                };
              })}
            />
          </StyledAbsolute>
          <Spacer x={2.5} />
        </StyledMenuOptionsDisplay>
      )}
      <Spacer y={1} />
      <StyledMenuOptions
        onClick={() => {
          setIsMenuVisible(true);
        }}
        ref={outsideClickMenuOptions}
      >
        <MoreHorizOutlined />
      </StyledMenuOptions>
      <Spacer y={1} />
    </StyledMenuOptionsCell>
  );
};

export const VirtualTableDS = <
  Key extends string,
  T extends Record<Key, string | number | null>,
>(
  props: VirtualTableProps<Key, T>,
) => {
  const keys = Object.keys(props.headers) as Key[];

  const isEmptyTable = props.data.length === 0;

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const setSearchValues = (values: T[]) => {
    props.searchBar?.setFilterValues(values);
    setIsSearchResult(values.length === 0);
  };

  return (
    <StyledContainer>
      {props.searchBar && (
        <>
          <StyledSearchBar>
            <SearchBarDS
              setFilterValues={setSearchValues}
              placeholder={t("home.searchbar")}
              values={props.searchBar.values}
              keysToTranslate={props.searchBar.keysToTranslate}
              findKeysToTranslate={props.searchBar.findKeysToTranslate}
              keysToIgnore={props.searchBar.keysToIgnore}
            />
          </StyledSearchBar>
          <Spacer y={3.5} />
        </>
      )}
      <StyledTable $height={props.height}>
        <StyledHeader>
          {!props.headerOverlayed ? (
            keys.map((column, i) => (
              <TableHeaderDS
                text={props.headers[column].text}
                key={column}
                sortAction={props.headers[column].sortAction}
                hasSmallWidth={props.hasSmallFirstRow && i === 0}
              />
            ))
          ) : (
            <StyledHeaderOverlayed>
              <Spacer y={1} />
              <StyledFlex>
                <Spacer x={1} />
                {props.headerOverlayed}
                <Spacer x={1} />
              </StyledFlex>
              <Spacer y={1} />
            </StyledHeaderOverlayed>
          )}
          {props.menuOptions && <Spacer x={4.5} />}
        </StyledHeader>
        {props.hasBackendError && <BackendError />}

        {isEmptyTable &&
          !isSearchResult &&
          !props.hasBackendError &&
          props.emptyContent &&
          props.emptyContent}
        {isEmptyTable && isSearchResult && !props.hasBackendError && (
          <EmptyTableComponent pageName="transactions" isSearchResult={true} />
        )}
        {!isEmptyTable && !props.hasBackendError && (
          <StyledRowContainer>
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <StyledFixedSizeList
                    height={height}
                    width={width}
                    itemSize={16 * 3.5}
                    itemCount={props.data.length}
                  >
                    {VirtualRow({
                      data: props.data,
                      keys: Object.keys(props.headers) as Key[],
                      render: props.render,
                      onClickRow: props.onClickRow,
                      menuOptions: props.menuOptions,
                      setRowHover: props.setRowHover,
                      hasSmallFirstRow: props.hasSmallFirstRow,
                    })}
                  </StyledFixedSizeList>
                );
              }}
            </AutoSizer>
          </StyledRowContainer>
        )}
      </StyledTable>
    </StyledContainer>
  );
};

const BackendError = () => {
  const { t } = useTranslation();
  return (
    <StyledBackendError>
      <Spacer y={2} />
      <EmptyTable />
      <Spacer y={4} />
      <StyledEmptyTableTitle>
        <TextCapitalized>{t(`table.backend.error.title`)}</TextCapitalized>
      </StyledEmptyTableTitle>
      <Spacer y={1} />
      <StyledBackendErrorBody>
        <TextCapitalized>
          {t(`table.backend.error.text.firstPart`)}
        </TextCapitalized>
      </StyledBackendErrorBody>
      <Spacer y={1} />
      <StyledBackendErrorBody>
        <TextCapitalized>
          <Trans i18nKey={`table.backend.error.text.secondPart`} />
        </TextCapitalized>
      </StyledBackendErrorBody>
      <Spacer y={2} />
    </StyledBackendError>
  );
};

const StyledEmptyTableTitle = styled.div`
  display: flex;
  ${typographies["Header/H1"]};
  text-align: center;
  color: ${colors["colors/text/black"]};
`;

const StyledBackendError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBackendErrorBody = styled.div`
  display: flex;
  max-width: 44rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledHeaderOverlayed = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Body/M"]};
  color: ${colors["colors/text/darkGrey"]};
  cursor: default;
`;

const StyledAbsolute = styled.div`
  pointer-events: auto;
  top: 3.5rem;
  z-index: 1;
  position: absolute;
`;
const StyledMenuOptionsDisplay = styled.div`
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
`;

const StyledText = styled.div<{ $hasAction: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typographies["Body/S"]};
  cursor: ${({ $hasAction }) => ($hasAction ? "pointer" : "default ")};
  color: ${colors["colors/text/black"]};
`;

const StyledRow = styled.div<{ $hasAction: boolean }>`
  display: flex;
  height: 3.5rem;
  min-width: fit-content;
  width: 100%;

  &:hover {
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }

  transition: all 0.15s ease;
  cursor: ${({ $hasAction }) => ($hasAction ? "pointer" : "default ")};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.25rem;
  }
`;

const StyledCell = styled.div<{
  $first: boolean;
  $hasSmallWidth: boolean;
}>`
  display: flex;
  align-items: center;
  min-width: ${({ $hasSmallWidth }) =>
    $hasSmallWidth ? "4.5rem" : "11.875rem"};
  max-width: ${({ $hasSmallWidth }) => ($hasSmallWidth ? "4.5rem" : "")};
  width: 100%;
  overflow: hidden;
`;
const StyledRowContainer = styled.div`
  flex-direction: column;
  height: calc(100vh - 19.25rem);
  min-width: 100vh;
  width: 100%;
`;

const StyledFixedSizeList = styled(FixedSizeList)`
  overflow-x: hidden !important;
  min-width: 100vh;
  width: 100%;
`;

const StyledTable = styled.div<{ $height?: number }>`
  overflow-x: auto;
  border-radius: 0.75rem;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  height: ${({ $height }) => ($height ? `${$height}rem` : "100%")};
  min-width: fit-content;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearchBar = styled.div`
  position: fixed;
`;

const StyledHeader = styled.div`
  display: flex;
  min-width: 100vh;
  width: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-bottom: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const StyledMenuOptionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
  position: relative;
  min-width: 4.5rem;
`;

const StyledMenuOptions = styled.div`
  display: flex;
  width: 2.5rem;
  min-height: 2.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${colors["colors/button/secondary/default"]};
  border: 0.0625rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.0625rem 0.25rem 0rem rgba(122, 122, 122, 0.08);
`;

const StyledFlex = styled.div`
  display: flex;
`;
