import {
  ToastContainer,
  SortingDirectionType,
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  LaunchPage,
  sortByDateTime,
  PageDS,
  TableDS,
  EmptyTableComponent,
  TableTagBadge,
  AddOutlined,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "./../homeSlice";
import {
  badgesListAsync,
  selectBadgesCreationStatus,
  selectBadgesList,
  selectBadgesListStatus,
  selectBadgesOrderedNumber,
  selectBadgeUpdateStatus,
  selectReplacementBadgeStatus,
} from "./badgesSlice";
import { unreachable } from "../../../utils";
import { BadgesList, BadgesListDisplayed } from "./badgesAPI";
import { BadgeSidePanel } from "./SidePanel";
import { useNavigate } from "react-router-dom";
import { AddBadgeModal } from "./modals/addBadgeModal";
import { listAllVehiclesInDbAsync } from "../vehicles/vehiclesSlice";
import { MandateUnsignedModal } from "./modals/mandateUnsignedModal";
import { AddMultipeBadgesModal } from "./modals/addMulitpleBadgesModal";
import { addressAsync } from "../cards/cardsSlice";

export const Badges = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isCreateBadgeModalVisible, setIsCreateBadgeModalVisible] =
    useState(false);
  const [isModalUnsignedModalVisible, setIsModalUnsignedModalVisible] =
    useState(false);
  const [isMultipleBadgesModalVisible, setIsMultipleBadgesModalVisible] =
    useState(false);

  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");
  const [listDisplayed, setListDisplayed] = useState<
    BadgesListDisplayed[] | null
  >(null);
  const company = useAppSelector(selectCompany);
  const badgesListInit = useAppSelector(selectBadgesList);
  const badgesListStatus = useAppSelector(selectBadgesListStatus);
  const createBadgesStatus = useAppSelector(selectBadgesCreationStatus);
  const updateBadgeStatus = useAppSelector(selectBadgeUpdateStatus);
  const replacementBadgeStatus = useAppSelector(selectReplacementBadgeStatus);
  const badgesCreationStatus = useAppSelector(selectBadgesCreationStatus);
  const badgesOrderedNumber = useAppSelector(selectBadgesOrderedNumber);

  const badgesList: BadgesList[] = useMemo(() => {
    return sortByDateTime(badgesListInit, "desc");
  }, [badgesListInit]);

  const findKeysToTranslate = (key: keyof BadgesList, value: string) => {
    switch (key) {
      case "status":
        return `badges.status.${value}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (company) {
      void dispatch(badgesListAsync(company.uuid));
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
    }
  }, [
    dispatch,
    company,
    createBadgesStatus,
    updateBadgeStatus,
    replacementBadgeStatus,
  ]);

  useEffect(() => {
    if (badgesList && badgesListStatus === "success") {
      const badgesDateTimeSorted = sortByDateTime(badgesList, "desc");
      setListDisplayed(badgesDateTimeSorted);
    }
  }, [badgesList, badgesListStatus]);

  useEffect(() => {
    if (badgesCreationStatus === "success" && badgesOrderedNumber) {
      badgesOrderedNumber === 1
        ? triggerToast(t("badges.creation.success.singular") || "", "valid")
        : triggerToast(
            t("badges.creation.success.plural", {
              number: badgesOrderedNumber,
            }) || "",
            "valid",
          );
    } else if (badgesCreationStatus === "failed") {
      triggerToast(t("badges.creation.failure") || "", "error");
    }
  }, [badgesCreationStatus, badgesOrderedNumber, t]);

  const sortBadgesByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const badgesAlphabeticalSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(badgesAlphabeticalSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  const sidePanelAction = useCallback(
    (props: Partial<BadgesList>) => {
      navigate(`/home/badges/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (company) {
      void dispatch(addressAsync(company.uuid));
    }
  }, [dispatch, company]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const searchBarProps = {
    values: badgesList,
    setFilterValues: setListDisplayed,
    keysToTranslate: ["status" as const],
    findKeysToTranslate,
    keysToIgnore: ["uuid" as const, "date" as const, "vehicleUuid" as const],
  };

  const render =
    (row: BadgesListDisplayed) => (key: keyof BadgesListDisplayed) => {
      switch (key) {
        case "status":
          return <TableTagBadge status={row[key]} />;
        case "number":
        case "registrationNumber":
        case "reference":
          return row[key];
      }
      unreachable(key);
    };

  const headers = {
    number: {
      text: t("badges.column.number"),
    },
    status: {
      text: t("badges.column.status"),
    },
    registrationNumber: {
      text: t("badges.column.registrationNumber"),
      sortAction: () => sortBadgesByAlphabeticalOrder("registrationNumber"),
    },
    reference: {
      text: t("badges.column.reference"),
      sortAction: () => sortBadgesByAlphabeticalOrder("reference"),
    },
  };

  const canOrderBadges = company && company.connectSepaMandateSigned;
  const cta = {
    title: t("badges.cta"),
    action: () => {
      canOrderBadges
        ? setIsCreateBadgeModalVisible(true)
        : setIsModalUnsignedModalVisible(true);
    },
    width: 12,
    leftIcon: <AddOutlined />,
  };

  const cta2 = {
    title: t("badges.cta2"),
    action: () => {
      canOrderBadges
        ? setIsMultipleBadgesModalVisible(true)
        : setIsModalUnsignedModalVisible(true);
    },
    width: 12,
    leftIcon: <AddOutlined />,
  };

  return (
    <>
      {isMultipleBadgesModalVisible ? (
        <PageDS
          title={t("badges.multipleBadges.title")}
          titleElement={{
            closeButtonAction: () => setIsMultipleBadgesModalVisible(false),
          }}
        >
          <AddMultipeBadgesModal
            onCloseModal={() => {
              setIsMultipleBadgesModalVisible(false);
            }}
          />
        </PageDS>
      ) : (
        <PageDS
          title={t("badges.title")}
          isEmptyTable={listDisplayed.length === 0}
          toaster={<ToastContainer />}
          searchBar={searchBarProps}
          cta={cta}
          cta2={cta2}
        >
          <TableDS<keyof BadgesListDisplayed, BadgesListDisplayed>
            data={listDisplayed}
            headers={headers}
            render={render}
            onClickRow={sidePanelAction}
            emptyContent={
              <EmptyTableComponent
                pageName="badges"
                isSearchResult={searchBarProps.values.length > 0}
                cta={cta}
              />
            }
          />
          <BadgeSidePanel />
          <AddBadgeModal
            visible={isCreateBadgeModalVisible}
            onCloseModal={() => {
              setIsCreateBadgeModalVisible(false);
            }}
          />
          <MandateUnsignedModal
            visible={isModalUnsignedModalVisible}
            onCloseModal={() => {
              setIsModalUnsignedModalVisible(false);
            }}
          />
        </PageDS>
      )}
    </>
  );
};
