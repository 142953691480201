import {
  sortByDateTime,
  SortingDirectionType,
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  sortByNumeric,
  SortByNumericType,
  Spacer,
  LaunchPage,
  EmptyTableComponent,
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  Tag,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { unreachable } from "../../../utils";
import {
  PublicBadgeTransactionDisplayed,
  PublicBadgeTransactionDisplayedRow,
  PublicBadgeTransactionDisplayedKey,
  BadgeTransactionDisplayedApi,
} from "./transactionsAPI";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  asfTransactionsListAsync,
  selectAsfTransactionsList,
  selectAsfTransactionsListStatus,
} from "./transactionsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCategoryName } from "./../rules/libDefaultDatas";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { TransactionsSidePanel } from "./SidePanel";
import { useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { VirtualTableDS } from "@qivia/ui/src/designSystem/components/virtualTable/VirtualTable";
import { AsfLogo } from "@qivia/ui/src/assets/assets";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const BadgeTransactionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);
  const [listDisplayed, setListDisplayed] = useState<
    PublicBadgeTransactionDisplayed[] | null
  >(null);
  const transactionsList: PublicBadgeTransactionDisplayed[] | null =
    useAppSelector(selectAsfTransactionsList);
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");
  const transactionsListStatus = useAppSelector(
    selectAsfTransactionsListStatus,
  );
  const [rowHoverUuid, setRowHoverUuid] = useState<string | null>(null);

  const setRowHover = useCallback(
    (props: Partial<BadgeTransactionDisplayedApi> | null) => {
      setRowHoverUuid(props?.uuid ?? null);
    },
    [],
  );

  const allTransactionsDisplayed: PublicBadgeTransactionDisplayed[] =
    useMemo(() => {
      if (!transactionsList) return [];
      return sortByDateTime([...transactionsList], "desc");
    }, [transactionsList]);

  useEffect(() => {
    if (company) {
      void dispatch(asfTransactionsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  const sortTransactionsByDate = useCallback(() => {
    if (!listDisplayed) return;
    const transactionsDateTimeSorted = sortByDateTime(
      listDisplayed,
      sortingDirection,
    );
    setListDisplayed(transactionsDateTimeSorted);
    setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
  }, [listDisplayed, sortingDirection]);

  const sortTransactionsByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const transactionsAlphabeticalSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(transactionsAlphabeticalSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  const sortTransactionsByAmount = useCallback(
    (column: string) => {
      if (!listDisplayed) return;

      const transactionsAmountSorted = sortByNumeric(
        listDisplayed,
        sortingDirection,
        column as keyof SortByNumericType,
      );

      setListDisplayed(transactionsAmountSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  const findKeysToTranslate = (
    key: keyof PublicBadgeTransactionDisplayed,
    value: string,
  ) => {
    switch (key) {
      case "category":
        return `transactions.category.${value}`;
      case "date":
        return dateFormatterDayMonthLongYearAndHourMinute2Digits(
          new Date(value),
        );
      default:
        return value;
    }
  };

  useEffect(() => {
    if (allTransactionsDisplayed && transactionsListStatus === "success") {
      setListDisplayed(allTransactionsDisplayed);
    }
  }, [allTransactionsDisplayed, transactionsListStatus]);

  const searchBarProps = {
    values: allTransactionsDisplayed,
    setFilterValues: setListDisplayed,
    keysToTranslate: ["category" as const, "date" as const],
    findKeysToTranslate,
    keysToIgnore: ["type" as const, "uuid" as const],
  };

  const render =
    (row: PublicBadgeTransactionDisplayedRow) =>
    (key: keyof PublicBadgeTransactionDisplayedKey) => {
      switch (key) {
        case "type":
          return (
            <StyledFlex>
              <Spacer x={0.5} />
              <AsfLogo />
            </StyledFlex>
          );
        case "date":
          return dateFormatterDayMonthLongYearAndHourMinute2Digits(
            new Date(row[key]),
          );
        case "amount":
          return <StyledAmount>{`${row[key].toString()} EUR`}</StyledAmount>;
        case "category": {
          const merchantCategory = getCategoryName(row[key]);
          return (
            <Tag
              text={t(`asfTransactions.category.${merchantCategory}`)}
              backgroundColor={
                colors["colors/surfaces/background/background_level2"]
              }
              textColor={colors["colors/text/black"]}
              colorHover={
                rowHoverUuid === row["uuid"]
                  ? colors["colors/surfaces/background/background_level0"]
                  : undefined
              }
            />
          );
        }
        case "registrationNumber":
          return (
            <StyledVehicle>
              {row[key]}
              {row["reference"] && (
                <>
                  <Spacer x={0.5} />
                  <Tag
                    text={row["reference"]}
                    textColor={colors["colors/text/black"]}
                    borderColor={colors["colors/borders/cells/cells"]}
                  />
                </>
              )}
            </StyledVehicle>
          );
        case "badgeNumber":
          return (
            <Tag
              text={row[key]}
              textColor={colors["colors/text/black"]}
              borderColor={colors["colors/borders/cells/cells"]}
            />
          );
        case "entranceStationName":
          return (
            <StyledStations>
              <div>{row[key]}</div>
              <div>{row["exitStationName"]}</div>
            </StyledStations>
          );
      }
      unreachable(key);
    };

  const headers = {
    type: {
      text: t("asfTransactions.page.column.type"),
    },
    date: {
      text: t("asfTransactions.page.column.date"),
      sortAction: () => sortTransactionsByDate(),
    },
    entranceStationName: {
      text: t("asfTransactions.page.column.entranceStationName"),
    },
    category: {
      text: t("asfTransactions.page.column.category"),
    },
    badgeNumber: {
      text: t("asfTransactions.page.column.badgeNumber"),
    },
    registrationNumber: {
      text: t("asfTransactions.page.column.registrationNumber"),
      sortAction: () =>
        sortTransactionsByAlphabeticalOrder("registrationNumber"),
    },
    amount: {
      text: t("asfTransactions.page.column.amount"),
      sortAction: () => sortTransactionsByAmount("amount"),
    },
  };

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "badges") {
    return;
  }
  return (
    <>
      <Spacer y={1} />
      <VirtualTableDS<
        keyof PublicBadgeTransactionDisplayed,
        PublicBadgeTransactionDisplayed
      >
        data={listDisplayed}
        headers={headers}
        render={render}
        hasSmallFirstRow={true}
        setRowHover={setRowHover}
        searchBar={searchBarProps}
        emptyContent={<EmptyTableComponent pageName={"transactions"} />}
      />
      <TransactionsSidePanel />
    </>
  );
};

const StyledStations = styled.div`
  ${typographies["Body/XS"]}
`;

const StyledVehicle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledAmount = styled.div<{
  $isCanceled?: boolean;
}>`
  text-decoration: ${({ $isCanceled }) =>
    $isCanceled ? "line-through" : "none"};
  ${typographies["Body/L"]}
`;
