import { RouteObject } from "react-router-dom";
import { Layout } from "../Layout";
import { Home } from "./home/home";
import { Register } from "./register/register";
import { Login } from "./login/login";
import { LogoutPage } from "../components/LogoutPage";
import { Welcome } from "./register/welcome";
import { Verification } from "./register/verification";
import { Transactions } from "./home/transactions";
import { Dashboard } from "./home/dashboard";
import { Vehicles } from "./home/vehicles";
import { Cards } from "./home/cards";
import { Rules } from "./home/rules";
import { Users } from "./home/users";
import { Drivers } from "./home/drivers";
import { Billings } from "./home/billings";
import { NotFoundPage } from "../components/NotFoundPage";
import { Thanks } from "./register/thanks";
import { PasswordForgotten } from "./login/passwordForgotten";
import { PasswordReset } from "./login/passwordReset";
import { RuleCreation } from "./home/rules/ruleCreation";
import { RuleUpdate } from "./home/rules/ruleUpdate";
import { DriverAccessRequest } from "./home/drivers/driversAccessRequest";
import { CompanyCreation } from "./home/companies/companyCreation";
import { PasswordCreation } from "./login/passwordCreation";
import { Badges } from "./home/badges";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "home",
        element: <Home />,
        children: [
          { path: "", element: <Dashboard /> },
          { path: "vehicles/:id?", element: <Vehicles /> },
          { path: "cards/:id?", element: <Cards /> },
          { path: "badges/:id?", element: <Badges /> },
          { path: "rules", element: <Rules /> },
          { path: "rules/:name", element: <Rules /> },
          { path: "rule_creation/:origin", element: <RuleCreation /> },
          { path: "rule/:id", element: <RuleUpdate /> },
          { path: "users/:tab?/:id?", element: <Users /> },
          { path: "drivers/:id?", element: <Drivers /> },
          {
            path: "drivers/access_request/:id?",
            element: <DriverAccessRequest />,
          },
          { path: "transactions/:tab?/:id?", element: <Transactions /> },
          { path: "billings/:tab?/:sepa_debit?", element: <Billings /> },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      { path: "logout", element: <LogoutPage /> },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "thanks",
        element: <Thanks />,
      },
      {
        path: "verification",
        element: <Verification />,
      },
      {
        path: "password_forgotten",
        element: <PasswordForgotten />,
      },
      {
        path: "password_reset",
        element: <PasswordReset />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "new_company",
        element: <CompanyCreation />,
      },
      {
        path: "new_password/:id",
        element: <PasswordCreation />,
      },
    ],
  },
];
