import { PageDS, Spacer, TabMenu, ToastContainer } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardTransactionsTab } from "./cardTransactionsTab";
import { BadgeTransactionsTab } from "./badgeTransactionsTab";

export const Transactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState<string>(params.tab ?? "cards");

  const tabs = [
    {
      name: "cards",
      title: t("transactions.tab.cards"),
    },
    // {
    //   name: "badges",
    //   title: t("transactions.tab.badges"),
    // },
  ];

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/transactions/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  return (
    <PageDS
      title={t("transactions.title")}
      subTitle={t("transactions.subTitle")}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Spacer y={0.5} />
        </>
      }
    >
      <CardTransactionsTab />
      <BadgeTransactionsTab />
    </PageDS>
  );
};
