import {
  sortByDateTime,
  SortingDirectionType,
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  sortByNumeric,
  SortByNumericType,
  Avatar,
  triggerToast,
  Spacer,
  Tag,
  LaunchPage,
  TableTagTransaction,
  EmptyTableComponent,
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { unreachable } from "../../../utils";
import {
  PublicTransactionsPropsType,
  PublicTransactionDisplayed,
  PublicTransactionDisplayedRow,
  PublicTransactionDisplayedKey,
} from "./transactionsAPI";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  authorizationsListAsync,
  selectAuthorizationsList,
  selectAuthorizationsListStatus,
  selectTransactionsList,
  selectTransactionsListStatus,
  transactionsListAsync,
  selectSendSmsStatus,
  selectHasBackendError,
  selectSendSmsReplaceInvalidStatus,
} from "./transactionsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCategoryName } from "./../rules/libDefaultDatas";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import styled from "styled-components";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { TransactionsSidePanel } from "./SidePanel";
import { useNavigate, useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { VirtualTableDS } from "@qivia/ui/src/designSystem/components/virtualTable/VirtualTable";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";

export const CardTransactionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const company = useAppSelector(selectCompany);
  const [listDisplayed, setListDisplayed] = useState<
    PublicTransactionDisplayed[] | null
  >(null);
  const transactionsList: PublicTransactionsPropsType[] | null = useAppSelector(
    selectTransactionsList,
  );
  const authorizationsRefusedList: PublicTransactionsPropsType[] | null =
    useAppSelector(selectAuthorizationsList);
  const [rowHoverUuid, setRowHoverUuid] = useState<string | null>(null);
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");
  const transactionsListStatus = useAppSelector(selectTransactionsListStatus);
  const authorizationsListStatus = useAppSelector(
    selectAuthorizationsListStatus,
  );
  const sendSmsStatus = useAppSelector(selectSendSmsStatus);
  const sendSmsReplaceInvalidStatus = useAppSelector(
    selectSendSmsReplaceInvalidStatus,
  );
  const hasBackendError = useAppSelector(selectHasBackendError);

  const allTransactionsDisplayed: PublicTransactionsPropsType[] =
    useMemo(() => {
      if (!transactionsList || !authorizationsRefusedList) return [];
      return sortByDateTime(
        [...transactionsList, ...authorizationsRefusedList],
        "desc",
      );
    }, [authorizationsRefusedList, transactionsList]);

  useEffect(() => {
    if (company) {
      void dispatch(transactionsListAsync(company.uuid));
      void dispatch(authorizationsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  const sortTransactionsByDate = useCallback(() => {
    if (!listDisplayed) return;
    const transactionsDateTimeSorted = sortByDateTime(
      listDisplayed,
      sortingDirection,
    );
    setListDisplayed(transactionsDateTimeSorted);
    setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
  }, [listDisplayed, sortingDirection]);

  const sortTransactionsByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const transactionsAlphabeticalSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(transactionsAlphabeticalSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  const sortTransactionsByAmount = useCallback(
    (column: string) => {
      if (!listDisplayed) return;

      const transactionsAmountSorted = sortByNumeric(
        listDisplayed,
        sortingDirection,
        column as keyof SortByNumericType,
      );

      setListDisplayed(transactionsAmountSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  useEffect(() => {
    if (
      sendSmsStatus === "success" ||
      sendSmsReplaceInvalidStatus === "success"
    ) {
      triggerToast(t(`transactions.sendSms.success`) || "", "valid");
    } else if (
      sendSmsStatus === "failed" ||
      sendSmsReplaceInvalidStatus === "failed"
    ) {
      triggerToast(t("transactions.sendSms.failure") || "", "error");
    }
  }, [sendSmsReplaceInvalidStatus, sendSmsStatus, t]);

  const findKeysToTranslate = (
    key: keyof PublicTransactionsPropsType,
    value: string,
  ) => {
    switch (key) {
      case "status":
        switch (value) {
          case "NO_SUPPORTING_DOCUMENT":
            return "transactions.status.noSupportingDocument";
          case "SUSPICIOUS":
            return "transactions.status.rejected"; //TO DO CHANGE
          default:
            return `transactions.status.${value.toLowerCase()}`;
        }
      case "category":
        return `transactions.category.${value}`;
      case "date":
        return dateFormatterDayMonthLongYearAndHourMinute2Digits(
          new Date(value),
        );
      case "supportingDocumentStatus":
        return `transactions.supportingDocument.${value}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (
      allTransactionsDisplayed &&
      transactionsListStatus === "success" &&
      authorizationsListStatus === "success"
    ) {
      setListDisplayed(allTransactionsDisplayed);
    }
  }, [
    allTransactionsDisplayed,
    authorizationsListStatus,
    transactionsListStatus,
  ]);

  const sidePanelAction = useCallback(
    (props: Partial<PublicTransactionsPropsType>) => {
      navigate(`/home/transactions/cards/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      const transactionSelected = allTransactionsDisplayed.find(
        (t) => t.uuid === params.id,
      );
      if (transactionSelected) {
        navigate(`/home/transactions/cards/${transactionSelected.uuid}`);
      }
    }
  }, [allTransactionsDisplayed, navigate, params.id]);

  const setRowHover = useCallback(
    (props: Partial<PublicTransactionsPropsType> | null) => {
      setRowHoverUuid(props?.uuid ?? null);
    },
    [],
  );

  const searchBarProps = {
    values: allTransactionsDisplayed,
    setFilterValues: setListDisplayed,
    keysToTranslate: [
      "status" as const,
      "category" as const,
      "date" as const,
      "supportingDocumentStatus" as const,
    ],
    findKeysToTranslate,
    keysToIgnore: [
      "uuid" as const,
      "merchantCity" as const,
      "cardLast4Digits" as const,
      "reasonsRefused" as const,
      "mileage" as const,
      "quantity" as const,
      "consumption" as const,
      "supportingDocumentUuid" as const,
      "driverPhone" as const,
      "driver" as const,
      "driverLastName" as const,
      "ruleName" as const,
      "ruleUuid" as const,
      "vehicleUuid" as const,
      "cardUuid" as const,
      "fuel" as const,
      "carbonEmission" as const,
      "areSupportingDocumentsMandatory" as const,
    ] as (keyof PublicTransactionDisplayedRow)[],
  };

  const render =
    (row: PublicTransactionDisplayedRow) =>
    (key: keyof PublicTransactionDisplayedKey) => {
      switch (key) {
        case "type":
          return <LittleCardAsset />;
        case "date":
          return dateFormatterDayMonthLongYearAndHourMinute2Digits(
            new Date(row[key]),
          );
        case "amount":
          return (
            <StyledAmount $isCanceled={row.status === "CANCELED"}>
              {`${row[key].toString()} EUR`}
            </StyledAmount>
          );
        case "category": {
          const merchantCategory = getCategoryName(row[key]);
          return (
            <Tag
              text={t(`transactions.category.${merchantCategory}`)}
              backgroundColor={
                colors["colors/surfaces/background/background_level2"]
              }
              textColor={colors["colors/text/black"]}
              colorHover={
                rowHoverUuid === row["uuid"]
                  ? colors["colors/surfaces/background/background_level0"]
                  : undefined
              }
            />
          );
        }
        case "status":
          return (
            <TableTagTransaction
              status={row[key]}
              isRowHover={rowHoverUuid === row["uuid"]}
            />
          );
        case "supportingDocumentStatus": {
          return (
            <>
              {row[key] === "-" ? (
                "-"
              ) : (
                <Tag
                  text={t(`transactions.supportingDocument.${row[key]}`)}
                  backgroundColor={
                    row[key] === "ACCEPTED"
                      ? colors["colors/system/success/success_ultraLight"]
                      : colors["colors/surfaces/background/background_level2"]
                  }
                  textColor={
                    row[key] === "ACCEPTED"
                      ? colors["colors/system/success/success_normal"]
                      : colors["colors/text/black"]
                  }
                />
              )}
            </>
          );
        }
        case "driverName": {
          if (!row[key]) {
            return "--";
          }
          const initials = row[key].split(" ").map((row) => row.charAt(0));
          return (
            <StyledCircle>
              <Avatar
                color={"mint"}
                size={"S"}
                firstName={initials[0].toUpperCase()}
                lastName={initials[initials.length - 1].toUpperCase()}
                isHover={rowHoverUuid === row["uuid"]}
              />
              <Spacer x={0.75} />
              {row[key]}
            </StyledCircle>
          );
        }
        case "merchantName":
          return row[key];
      }
      unreachable(key);
    };

  const headers = {
    type: {
      text: t("transactions.page.column.type"),
    },
    date: {
      text: t("transactions.page.column.date"),
      sortAction: () => sortTransactionsByDate(),
    },
    merchantName: {
      text: t("transactions.page.column.merchantName"),
      sortAction: () => sortTransactionsByAlphabeticalOrder("merchantName"),
    },
    category: {
      text: t("transactions.page.column.category"),
    },
    driverName: {
      text: t("transactions.page.column.driverName"),
    },
    status: {
      text: t("transactions.page.column.status"),
    },
    supportingDocumentStatus: {
      text: t("transactions.page.column.supportingDocument"),
    },
    amount: {
      text: t("transactions.page.column.amount"),
      sortAction: () => sortTransactionsByAmount("amount"),
    },
  };

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "cards") {
    return;
  }

  return (
    <>
      <Spacer y={1} />
      <VirtualTableDS<
        keyof PublicTransactionDisplayed,
        PublicTransactionDisplayed
      >
        data={listDisplayed}
        headers={headers}
        render={render}
        onClickRow={sidePanelAction}
        setRowHover={setRowHover}
        hasSmallFirstRow={true}
        hasBackendError={hasBackendError}
        searchBar={searchBarProps}
        emptyContent={<EmptyTableComponent pageName={"transactions"} />}
      />
      <TransactionsSidePanel />
    </>
  );
};

const StyledCircle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAmount = styled.div<{
  $isCanceled?: boolean;
}>`
  text-decoration: ${({ $isCanceled }) =>
    $isCanceled ? "line-through" : "none"};
  ${typographies["Body/L"]}
`;
