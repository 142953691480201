import { colors } from "../../../styles/figmaColors";
import { useTranslation } from "react-i18next";
import {
  AutoModeOutlined,
  HideSourceOutlined,
  TaskAltOutlined,
} from "../../materialUi/materialUi";
import { Tag } from "../Tag/Tag";

type Status = "IN_CIRCULATION" | "ORDERED" | "DEACTIVATED";

const statusProps = {
  IN_CIRCULATION: {
    backgroundColor: colors["colors/system/success/success_ultraLight"],
    textColor: colors["colors/system/success/success_normal"],
    iconColor: colors["colors/system/success/success_normal"],
    iconLeft: <TaskAltOutlined />,
  },
  ORDERED: {
    backgroundColor: colors["colors/system/informative/informative_ultraLight"],
    textColor: colors["colors/system/informative/informative_normal"],
    iconColor: colors["colors/system/informative/informative_normal"],
    iconLeft: <AutoModeOutlined />,
  },
  DEACTIVATED: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    textColor: colors["colors/text/black"],
    iconColor: colors["colors/text/black"],
    iconLeft: <HideSourceOutlined />,
  },
};

interface categoryTagProps {
  status: Status;
}

export const TableTagVehicle = (props: categoryTagProps) => {
  const vehicleStatus = statusProps[props.status];
  const { t } = useTranslation();

  return (
    <>
      <Tag
        text={t(`vehicles.status.${props.status}`)}
        backgroundColor={vehicleStatus.backgroundColor}
        textColor={vehicleStatus.textColor}
        iconColor={vehicleStatus.iconColor}
        //iconLeft={vehicleStatus.iconLeft}
      />
    </>
  );
};
