import { colors } from "../../../styles/figmaColors";
import { useTranslation } from "react-i18next";
import {
  LockOutlined,
  NotInterestedOutlined,
  TaskAltOutlined,
  WatchLaterOutlined,
} from "../../materialUi/materialUi";
import { Tag } from "../Tag/Tag";

type Status = "ACTIVE" | "IN_OPPOSITION" | "TO_ACTIVATE" | "LOCKED";

const statusProps = {
  ACTIVE: {
    backgroundColor: colors["colors/system/success/success_ultraLight"],
    textColor: colors["colors/system/success/success_normal"],
    iconColor: colors["colors/system/success/success_normal"],
    iconLeft: <TaskAltOutlined />,
  },
  IN_OPPOSITION: {
    backgroundColor: colors["colors/system/error/error_ultraLight"],
    textColor: colors["colors/system/error/error_normal"],
    iconColor: colors["colors/system/error/error_normal"],
    iconLeft: <NotInterestedOutlined />,
  },
  TO_ACTIVATE: {
    backgroundColor: colors["colors/system/informative/informative_ultraLight"],
    textColor: colors["colors/system/informative/informative_normal"],
    iconColor: colors["colors/system/informative/informative_normal"],
    iconLeft: <WatchLaterOutlined />,
  },
  LOCKED: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    textColor: colors["colors/text/black"],
    iconColor: colors["colors/text/black"],
    iconLeft: <LockOutlined />,
  },
};

interface categoryTagProps {
  status: Status;
}

export const TableTagCard = (props: categoryTagProps) => {
  const cardStatus = statusProps[props.status];
  const { t } = useTranslation();

  return (
    <>
      <Tag
        text={t(`cards.status.${props.status}`)}
        backgroundColor={cardStatus.backgroundColor}
        textColor={cardStatus.textColor}
        iconColor={cardStatus.iconColor}
        //iconLeft={cardStatus.iconLeft}
      />
    </>
  );
};
